<mat-toolbar>
    <span class="pulte-vertical-align">
        <img src="../assets/PG_Logo_2022_Horizontal_Y&W.png" alt="PHD Contract Setup" title="PHD Contract Setup" class="phd-logo" />
    </span>
    <!-- This fills the remaining space of the current row -->
    <span class="fill-remaining-space"></span>
    <button mat-raised-button *ngIf="showToolBarButtons" color="accent" (click)="backToTemplates.emit()">
        <mat-icon>arrow_back</mat-icon>
        Templates
    </button>

    <button mat-raised-button *ngIf="showToolBarButtons" color="accent" (click)="saveToCloud.emit()">
        <mat-icon>cloud_upload</mat-icon>
        Save
    </button>
</mat-toolbar>
